<template>
  <div>
    <div class="field">
      <label>
        <span class="icon is-small">
          <i class="fal fa-bars" />
        </span>
        <span>Items per page</span>
      </label>
    </div>
    <DropdownSelect
      v-bind="{ options, pageSize, selected: pageSize }"
      @toggle="toggle"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DataTablePageSize',
  data: () => ({
    pageSize: 15,
    options: [15, 30, 50, 75, 100]
  }),
  components: {
    DropdownSelect: () => import('modules/shared/dropdown/DropdownSelect')
  },
  watch: {
    pageSize() {
      this.setPageSize(this.pageSize)
      this.$mxp.track(`stockview_pages_select_${this.pageSize}`)
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['setPageSize']),
    toggle(option) {
      this.pageSize = option
    }
  }
}
</script>
